import { useState, useEffect } from "react";
import "../assets/Gpt.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import YESBOT from "../images/YESBOT.png";
import { auth } from "../firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

// // 로컬
const host = "http://127.0.0.1:5001/yesbot-1022f/us-central1/api/";
// 배포
// const host = "https://us-central1-yesbot-1022f.cloudfunctions.net/api/";

async function processMessageToChatGPT(threadID, chatMessages, onSuccess) {
  await fetch(host + "chat?chat=" + chatMessages + "&threadID=" + threadID, {
    method: "GET",
  }).then(onSuccess);
}

async function getThreadID(onSuccess) {
  await fetch(host + "thread", {
    method: "GET",
  }).then(onSuccess);
}

function Gpt() {
  const nav = useNavigate();
  const [threadID, setThreadID] = useState();
  const [messages, setMessages] = useState([
    {
      message: "무엇이든 물어보세요! 무엇을 도와드릴까요??",
      sentTime: "just now",
      sender: "ChatGPT",
      direction: "incoming",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setIsTyping(true);
    await processMessageToChatGPT(threadID, message, async (data) => {
      const apiResponse = await data.json();
      const result = [];
      result.push(...newMessages);
      result.push({
        message: apiResponse.response.message,
        sender: "ChatGPT",
        direction: "incoming",
      });
      if (
        apiResponse.response.citedFileIds &&
        apiResponse.response.citedFileIds.length > 0
      ) {
        for (let i = 0; i < apiResponse.response.citedFileIds.length; i++) {
          const citedFileId = apiResponse.response.citedFileIds[i];
          result.push({
            message: `
          <div class="custom-message-container">
              <p> 자료 다운로드 링크 : <a href="${host}download?fileID=${citedFileId}">여기를 클릭하세요 </a> </p>
              <button onclick="location.href='${host}download?fileID=${citedFileId}'">다운로드</button>
          </div>
          `,
            sender: "ChatGPT",
            direction: "incoming",
          });
        }
      }
      setMessages(result);
      setIsTyping(false);
    });
  };

  useEffect(() => {
    getThreadID(async (data) => {
      const apiResponse = await data.json();
      setThreadID(apiResponse.threadID);
    });
  }, []);

  useEffect(() => {
    // 사용자 상태를 감시합니다.
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser === null || currentUser.emailVerified === false) {
        // 로그인하지 않은 상태거나, 이메일 인증되지 않은 사용자인 경우
        nav("/");
      }
    });

    // 컴포넌트가 언마운트될 때 구독을 해제합니다.
    return () => unsubscribe();
  }, []);

  if (threadID === undefined) {
    return <div>Loading...</div>;
  }
  return (
    <div className="Gpt">
      <div className="gpt_left">
        <div className="black-nav">
          <div style={{ cllor: "white", fontSize: "40px" }}>
            <img src={YESBOT} className="YESBOT" alt="React" />
            &nbsp; 양전자시스템 ChatBot{" "}
          </div>
        </div>
        <div
          style={{
            float: "left",
            position: "relative",
            height: "800px",
            width: "700px",
          }}
        >
          <MainContainer>
            <ChatContainer>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator content="조금만 기다리세요. YESBot이 분석중입니다." />
                  ) : null
                }
              >
                {messages.map((message, i) => {
                  console.log(message);
                  return <Message key={i} model={message} />;
                })}
              </MessageList>
              <MessageInput
                placeholder="최대한 질문을 자세하게 적어주세요"
                onSend={handleSend}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
      <div className="gpt_right">
        <div className="question-nav">
          <div style={{ cllor: "white", fontSize: "40px" }}>
            <img src={YESBOT} className="YESBOT" alt="React" />
            &nbsp; 다음과 같이 질문해주세요{" "}
          </div>
        </div>

        <div class="container">
          <div class="box">현재 YESBOT은 아래의 질문에 대답할수 있어요.</div>
          <div class="box">1. Linear Alarm 원인 & 해결 방법 </div>
          <div class="box"> </div>
          <div class="box">2. 장비 Set-Up Manual </div>
          <div class="box"> - Align Recipe 작성 방법 </div>
          <div class="box"> - Probe Card Setting 방법 </div>
          <div class="box"> - Linear 부하율 Tuning </div>
          <div class="box"> - Linear Home Off-Set Tuning </div>
          <div class="box"></div>
          <div class="box">3. 신뢰성 장치 Calibration Manual </div>
          <div class="box"></div>
          <div class="box">4. 기타 사양서 </div>
          <div class="box"></div>
          <div class="box">현재 YESBOT Version 0.2 </div>
        </div>
      </div>
    </div>
  );
}

export default Gpt;
